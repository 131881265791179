import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import AppsIcon from "@material-ui/icons/Apps";
import { Avatar } from "@material-ui/core";
import Search from "../components/Search";

function Home() {

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className="centered">
      <h3>WHAT ARE WE & WHY DO WE EXIST</h3>
      <br/>
      <p>    
       2020 was a crazy year. It's completely normal to feel lost in such times and environment since, as Victor Frankl said, an abnormal response to a bizarre situation is normal itself! The current state of the world and our perception of it has only been aggravated by social media fueling fake news, constantly bombarding us, and leaving us more confused.
      </p>
      <br/>
      <p>
        <a href="https://www.linkedin.com/in/pompiliofiore/" target="blank">Pompilio Fiore</a> and <a href="https://www.linkedin.com/in/b0bbybaldi/" target="blank">Roberto Baldizon</a> build this site to search for reliable sources without ads or personalized results with minimal bias. Thus with such a tool, harsh realities can be discovered, understood, and addressed from a multiangle but truthful perspective to enable a more cooperative society.
      </p>
    </div>
  );

  return (
    <div className="home">
      <div className="home__header">
        <div className="home__headerLeft">

        <div onClick={handleOpen} onKeyDown={handleOpen} role="button" tabIndex={0}>About</div>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>

          {/* <Link to="/store">Store</Link> */}
        </div>
        <div className="home__headerRight">
          {/* <Link to="/email">Safe Mail</Link>
          <Link to="/images">Images</Link>
          <AppsIcon /> */}
          {/* <Avatar /> */}
        </div>
      </div>
      <div className="home__body">
        <img
          src="./logo.png"
          alt="fookfake.news logo"
        />
        <div className="home_inputContainer">
          <Search />
        </div>
        <img
          src="./tagline.png"
          alt="fookfake.news tagline"
          width="200px"
        />
      </div>
    </div>
  );
}

export default Home;
